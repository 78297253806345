/*globals
    App:false,
    define:false,
    UIComponents:false
 */

define([
    'jquery',
    'can',
    'can/map',
    'can/view',
    'ui_components/main_menu/main_menu',
    'jquerypp/controller/controller',
], function($, can) {
    'use strict';

    /**
     * @class App.Sidemenu
     * @extends jQuery.Controller
     */
    $.Controller.extend('App.Sidemenu',
        /** @static */
        {
            defaults: {
                appData: null,
                menuWrapper: '.js-side-menu-wrapper',
                // The icon that, when clicked, toggles the ``menu``.
                menuIcon: '.js-menu-icon',
            }
        },
        /** @prototype */
        {
            '{menuIcon} click': function(elm, event) {
                this.renderMenu();
            },

            renderMenu: function () {
                this.element.find(this.options.menuWrapper).empty();
                this.element.find(this.options.menuWrapper).html(
                    can.view('#uc-main-menu-template')({ isOpen: true })
                );
            },
        }
    );

    $(document).ready(function () {
        App.Sidemenu.newInstance($(document.body));
    });
});
