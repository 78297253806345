import { RelayEnvironmentProvider } from 'relay-hooks';
import type { Environment } from 'relay-runtime';

import RootErrorBoundary from 'ms-helpers/RootErrorBoundary';

import MainMenuStandalone, {
  type Props as MainMenuStandaloneProps,
} from './MainMenuStandalone';

export default function MainMenuAdmin({
  relayEnvironment,
  ...props
}: { relayEnvironment: Environment } & MainMenuStandaloneProps) {
  return (
    <RootErrorBoundary>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <MainMenuStandalone {...props} />
      </RelayEnvironmentProvider>
    </RootErrorBoundary>
  );
}
