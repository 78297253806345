export const newTeacherDashboardPath = '/teacher/dashboard';
export const newTeacherDashboardUrl = '/teacher/dashboard';
export const newTeacherDashboardAvatarPath = '/teacher/dashboard/avatar';
export const newTeacherDashboardAvatarUrl = '/teacher/dashboard/avatar';
export const newTeacherDashboardSettingsPath = '/teacher/dashboard/settings';
export const newTeacherDashboardSettingsUrl = '/teacher/dashboard/settings';
export const newTeacherDashboardChangePasswordPath =
  '/teacher/dashboard/settings/change-password';
export const newTeacherDashboardChangePasswordUrl =
  '/teacher/dashboard/settings/change-password';
export const newTeacherDashboardSchoolPath = '/teacher/dashboard/schools';
export const newTeacherDashboardSchoolUrl = '/teacher/dashboard/schools';
export const newTeacherDashboardClassPath = '/teacher/dashboard/classes';
export const newTeacherDashboardClassUrl = '/teacher/dashboard/classes';
export const teacherDashboardCreateClassPath =
  '/teacher/dashboard/classes/create';
export const teacherDashboardCreateClassUrl =
  '/teacher/dashboard/classes/create';
