import { css, StyleSheet } from 'aphrodite';
import { useCallback, useEffect, useState } from 'react';

import Portal from 'ms-helpers/Portal';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import Menu from 'ms-ui-primitives/Menu';
import Overlay, { exitAnimationDuration } from 'ms-ui-primitives/Overlay';
import { usePrevious } from 'ms-utils/hooks/usePrevious';
import { useUnmountEffect } from 'ms-utils/hooks/useUnmountEffect';
import type { UserType } from 'ms-utils/misc';

import linkGroupsForUserType from './linkGroupsForUserType';

export type Route = {
  name: string;
  url: string;
};

type Props = {
  accountsAvatarUrl: string;
  avatarUrl: string;
  firstName: string;
  isOpen: boolean;
  onClose: () => void;
  onExitAnimationEnd?: (() => void) | undefined;
  routes: ReadonlyArray<Route>;
  userType: UserType;
  debug?: boolean | undefined;
};

const menuEnterKeyframes = {
  '0%': { transform: 'translateX(-100%)' },
  '100%': { transform: 'translateX(0%)' },
};

const menuExitKeyframes = {
  '0%': { transform: 'translateX(0%)' },
  '100%': { transform: 'translateX(-100%)' },
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  menuWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1,
    animationName: menuEnterKeyframes,
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
  },
  menuWrapperExit: {
    animationName: menuExitKeyframes,
    animationDuration: `${exitAnimationDuration}ms`,
  },
});

export default function MainMenuPresentational(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [animateClose, setAnimateClose] = useState(false);
  const prevProps = usePrevious(props);
  const { featureFlags } = useViewer();

  useEffect(() => {
    if (props.isOpen && !prevProps?.isOpen) openMenu();
    else if (!props.isOpen && prevProps?.isOpen) closeMenu();
  });

  function openMenu() {
    setIsOpen(true);
  }

  const { onExitAnimationEnd } = props;
  const closeMenu = useCallback(
    (closeImmediately: boolean = false) => {
      function closePortal() {
        setIsOpen(false);
        setAnimateClose(false);
        onExitAnimationEnd?.();
      }

      if (closeImmediately) {
        closePortal();
        return;
      }

      setAnimateClose(true);
      window.setTimeout(() => {
        closePortal();
      }, exitAnimationDuration);
    },
    [onExitAnimationEnd],
  );

  useEffect(() => {
    if (props.isOpen) openMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUnmountEffect(() => {
    if (props.isOpen) closeMenu();
  });

  return (
    <Portal isOpen={isOpen} hasScrollPrevention>
      <div className={css(styles.wrapper)}>
        <Overlay onClick={props.onClose} animateClose={animateClose} />
        <div
          className={css(
            styles.menuWrapper,
            animateClose && styles.menuWrapperExit,
          )}
        >
          <Menu
            firstName={props.firstName}
            userType={props.userType}
            avatarUrl={props.avatarUrl}
            accountsAvatarUrl={props.accountsAvatarUrl}
            linkGroups={linkGroupsForUserType(
              props.userType,
              props.routes,
              featureFlags,
              props.debug,
            )}
          />
        </div>
      </div>
    </Portal>
  );
}
