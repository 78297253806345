import { css, StyleSheet } from 'aphrodite';

import { fontSize, fontWeight, fontFamily, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import type { UserType } from 'ms-utils/misc';

import userTypeToDisplay from './userTypeToDisplay';

type Props = {
  avatarUrl: string;
  accountsAvatarUrl: string;
  firstName: string;
  userType: UserType;
};

const styles = StyleSheet.create({
  avatar: {
    width: 56,
    height: 56,
    borderRadius: 30,
    display: 'block',
  },
  avatarLink: {
    marginRight: 20,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 25px',
    flexBasis: 116,
    flexShrink: 0,
    fontFamily: fontFamily.body,
  },
  userFirstName: {
    color: colors.white,
    fontSize: fontSize.menuHeading,
    fontWeight: fontWeight.normal,
    lineHeight: lineHeight.heading,
  },
  userType: {
    color: colors.dustyGray,
    fontSize: fontSize.small,
    fontWeight: fontWeight.normal,
    lineHeight: lineHeight.heading,
  },
  userInfo: {
    display: 'block',
  },
});

const hasAccessToAvatarPage = (userType: UserType): boolean =>
  !['student.expired', 'student.student_paid.unpaid'].includes(userType);

const Header = ({
  avatarUrl,
  accountsAvatarUrl,
  firstName,
  userType,
}: Props) => (
  <div className={css(styles.container)}>
    {hasAccessToAvatarPage(userType) ? (
      <a href={accountsAvatarUrl} className={css(styles.avatarLink)}>
        <img alt="user avatar" src={avatarUrl} className={css(styles.avatar)} />
      </a>
    ) : (
      <span className={css(styles.avatarLink)}>
        <img alt="user avatar" src={avatarUrl} className={css(styles.avatar)} />
      </span>
    )}
    <div className={css(styles.userInfo)}>
      <div className={css(styles.userFirstName)}>{firstName}</div>
      <div className={css(styles.userType)}>{userTypeToDisplay(userType)}</div>
    </div>
  </div>
);

export default Header;
