import { Suspense, createContext, useContext } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import SendViewerDataChangeToRN from 'ms-helpers/Viewer/ViewerProvider/SendViewerDataChangeToRN';
import { FEATURE_FLAG_NAMES_FOR_TEST_MOCKS } from 'ms-helpers/Viewer/ViewerProvider/featureFlags';
import { FEATURE_FLAG_V2_NAMES_FOR_TEST_MOCKS } from 'ms-helpers/Viewer/ViewerProvider/featureFlagsV2';
import { InvariantViolation } from 'ms-utils/app-logging';
import { publicEnvironment } from 'ms-utils/relay';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type {
  AccountType as AccountTypeEnum,
  CohortType,
  ViewerProviderQuery,
  ViewerProviderQueryResponse,
} from './__generated__/ViewerProviderQuery.graphql';
import { mutation } from './featureFlagsMutation';

export type ViewerQueryResponse = ViewerProviderQueryResponse;
export type UpdateFeatureFlags = ReturnType<typeof mutation>;
export type Role = 'Teacher' | 'Student' | 'Other';
type Viewer = NonNullable<ViewerProviderQueryResponse['viewer']>;
type Profile = Viewer['profile'];
type UserType = Viewer['userType'];
export type FeatureFlags = Viewer['featureFlags'];
export type FeatureFlagsV2 = Viewer['featureFlagsV2'];
export type ViewerPayload = {
  userId: string;
  userType: string;
  userPk: number;
  cohortType: CohortType;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  createdAt: string;
  profileId: string;
  lanternProfileId: string | null | undefined;
  selfReportedGrade: number;
  focusSyllabusPk: number;
  syllabusFocusId: string;
  isFloridaUser: boolean;
  isVirginiaUser: boolean;
  countryCode: string | null | undefined;
  accountType: AccountTypeEnum;
  isPartnerUser: boolean;
  isHeadTeacher: boolean;
  isSchoolAdmin: boolean;
  isTeacherStudent: boolean;
  // TODO: Deprecate `hasMathspaceApp`, use `useCanAccessMathspaceApp` hook instead
  hasMathspaceApp: boolean;
  hasAdminReport: boolean;
  // TODO: Once we have a student SPA move this field there
  canHaveAssignedTasks: boolean;
  isInClasses: boolean;
  // this is distinct from the account type because students and teachers can both be "STAFF"
  // this key provides a clear distinction between the two roles
  role: Role;
  featureFlags: FeatureFlags;
  updateFeatureFlags: UpdateFeatureFlags;
  featureFlagsV2: FeatureFlagsV2;
  isEmailVerified: boolean;
  // TODO these might need to all be readonly
  learningFocus:
    | {
        id: string;
        title: string;
        strand: {
          id: string;
        };
      }
    | null
    | undefined;
  learningFocusStatus: 'UNSET' | 'INVALID' | 'VALID' | undefined;
  skillsBook:
    | {
        shouldDefaultToSkillsTab: true;
        id: string;
      }
    | {
        shouldDefaultToSkillsTab: false;
        id?: never;
      };
  aiHintOpenPromptMonthlyLimitRemaining: number;
};
const query = graphql`
  query ViewerProviderQuery {
    viewer {
      cohortType
      country {
        code
      }
      syllabusFocus {
        id
        pk
        textbookType
      }
      id
      userType
      pk
      firstName
      lastName
      email
      createdAt
      profile {
        ... on Teacher {
          id
          isHeadTeacher
          isSchoolAdmin
          canAccessAdminReport
          isEmailVerified
          __typename
          school {
            countrySubdivision {
              code
            }
            availableSyllabuses {
              id
              textbookType
            }
          }
          teacherPreference {
            hasDefaultedToSkillsTab
          }
          aiHintOpenPromptMonthlyLimitRemaining
        }
        ... on Student {
          id
          __typename
          isTeacherStudent
          studentClasses: classes(first: 1) {
            edges {
              node {
                id
              }
            }
          }
          studentSchool: school {
            countrySubdivision {
              code
            }
          }
          assignedTasks(limit: 1) {
            id
          }
          aiHintOpenPromptMonthlyLimitRemaining
        }
      }
      accountType
      featureFlags: typedFeatureFlags {
        ...featureFlags @relay(mask: false)
      }
      featureFlagsV2: featureFlagsV2 {
        ...featureFlagsV2 @relay(mask: false)
      }
      isPartnerUser
    }
    lantern {
      viewer {
        id
        __typename
        ... on LanternStudent {
          learningFocus {
            id
            title
            strand {
              id
            }
          }
          learningFocusStatus
          selfReportedGrade {
            id
            shortTitle
          }
        }
      }
    }
  }
`;

/**
 * Check whether the student can have tasks.
 * At the moment there is no direct way of knowing this.
 * We make our decision based on three factors:
 * - whether the student is part of any class
 * - whether the student has any assigned tasks
 * - whether the student has paid their subscription.
 * We don't want to hide and show Tasks UI elements (especially the nav item)
 * depending on the state the student happens to be at a particular point
 * in time so we need to cover both of the following edge cases:
 * - the student who is part of a real school happens to have no assigned tasks
 * - the student who is part of a real school and has assigned tasks
 * happens to be not part of any class.
 * Please amend this function as needed as the work on Packages continues
 * and better checks or more edge cases are discovered.
 * @param {Profile} profile - Viewer profile.
 * @returns {boolean} Whether a viewer can have assigned tasks.
 */
function canHaveAssignedTasks({
  userType,
  profile,
}: {
  userType: UserType;
  profile: Profile;
}): boolean {
  if (profile == null) return false;
  switch (profile.__typename) {
    case 'Teacher': {
      return false;
    }
    case 'Student': {
      return (
        (profile.studentClasses.edges.length > 0 ||
          profile.assignedTasks.length > 0) &&
        userType !== 'student.student_paid.unpaid'
      );
    }
    default: {
      return false;
    }
  }
}

// NOTE undefined | null is probably unecessary, pick one. The previous types used
// this pattern, so there are potentially call sites where the logic will be busted
// if we collapse this to just using one of them.
// TODO review all call sites, and collapse usage to just one of these missing values.
export const ViewerContext = createContext<ViewerPayload | undefined | null>(
  undefined,
);

export function createViewerPayload(
  props: ViewerProviderQueryResponse,
): ViewerPayload {
  const { lantern, viewer } = props;
  if (viewer == null) throw new InvariantViolation('Viewer does not exist');
  const profile = viewer.profile;
  let profileId = '';
  let role: Role = 'Student';
  let isHeadTeacher = false;
  if (viewer.accountType === 'INACTIVE') {
    role = 'Other';
  } else if (viewer.accountType === 'EXTERNAL') {
    role = 'Other';
  } else {
    if (profile == null)
      throw new InvariantViolation('Viewer does not have a valid profile');
    if (profile.__typename === '%other')
      throw new InvariantViolation(
        'Profile type is invalid. Viewer must be a teacher or a student',
      );
    profileId = profile.id;
    role = profile.__typename;
    isHeadTeacher =
      profile.__typename === 'Teacher' ? profile.isHeadTeacher : false;
  }
  let countrySubdivision:
    | {
        readonly code: string;
      }
    | {} = {};
  if (profile !== null) {
    const { __typename } = profile;
    switch (__typename) {
      case 'Teacher':
        countrySubdivision = profile.school.countrySubdivision ?? {};
        break;
      case 'Student':
        countrySubdivision = profile.studentSchool?.countrySubdivision ?? {};
        break;
      case '%other':
        countrySubdivision = {};
        break;
      default:
        assertUnreachable(__typename);
    }
  }
  // Get the student's self reported grade by parsing the short title of the grade
  // If the selfReportedGrade is null/undefined or if value is NaN, then default to 0
  // Note that '10A' will parse to '10' but 'IM3' will parse to 'NaN'
  const selfReportedGrade =
    parseInt(lantern.viewer?.selfReportedGrade?.shortTitle ?? '0', 10) || 0;
  let skillsBookId = null;
  if (
    profile?.__typename === 'Teacher' &&
    profile.school.availableSyllabuses !== null &&
    viewer.featureFlags.showSkillsBookByDefault &&
    !profile.teacherPreference?.hasDefaultedToSkillsTab
  ) {
    skillsBookId =
      profile.school.availableSyllabuses.find(
        syllabus => syllabus.textbookType === 'SKILLS_BOOK',
      )?.id ?? null;
  }
  // We can do feature flag mapping here, turning plan flags into groups of real flags for
  // consumption.
  return {
    userId: viewer.id,
    userType: viewer.userType,
    userPk: viewer.pk,
    cohortType: viewer.cohortType,
    userFirstName: viewer.firstName,
    userLastName: viewer.lastName,
    userEmail: viewer.email,
    createdAt: viewer.createdAt,
    profileId,
    lanternProfileId: lantern.viewer?.id,
    focusSyllabusPk: viewer.syllabusFocus.pk,
    syllabusFocusId: viewer.syllabusFocus.id,
    selfReportedGrade,
    isFloridaUser: viewer.syllabusFocus.textbookType === 'CORE_TEXTBOOK',
    isVirginiaUser:
      'code' in countrySubdivision
        ? countrySubdivision.code === 'US-VA'
        : false,
    countryCode: viewer.country != null ? viewer.country.code : null,
    accountType: viewer.accountType,
    isPartnerUser: viewer.isPartnerUser,
    isHeadTeacher,
    isSchoolAdmin: profile?.__typename === 'Teacher' && profile.isSchoolAdmin,
    isTeacherStudent:
      viewer.profile?.__typename === 'Student' && !viewer.isPartnerUser
        ? viewer.profile.isTeacherStudent
        : false,
    hasAdminReport:
      profile?.__typename === 'Teacher' && profile.canAccessAdminReport,
    hasMathspaceApp:
      viewer.featureFlags.worksheets ||
      viewer.featureFlags.textbook ||
      viewer.featureFlags.templates ||
      viewer.featureFlags.assignableTasks,
    canHaveAssignedTasks: canHaveAssignedTasks({
      userType: viewer.userType,
      profile: viewer.profile,
    }),
    isInClasses:
      viewer.profile?.__typename === 'Student' &&
      viewer.profile.studentClasses.edges.length > 0,
    featureFlags: {
      ...viewer.featureFlags,
    },
    role,
    updateFeatureFlags: mutation({
      environment: publicEnvironment,
    }),
    featureFlagsV2: {
      ...viewer.featureFlagsV2,
    },
    isEmailVerified:
      viewer.profile?.__typename === 'Teacher'
        ? viewer.profile.isEmailVerified
        : // TODO: Figure out what a good default value here is.
          // Student's dont get verified emails, so "false" seems like
          // it's not representantive of the intent here
          false,
    learningFocus: lantern.viewer?.learningFocus,
    learningFocusStatus: lantern.viewer?.learningFocusStatus,
    skillsBook:
      skillsBookId !== null
        ? {
            shouldDefaultToSkillsTab: true,
            id: skillsBookId,
          }
        : {
            shouldDefaultToSkillsTab: false,
          },
    aiHintOpenPromptMonthlyLimitRemaining:
      viewer.profile?.__typename === 'Student' ||
      viewer.profile?.__typename === 'Teacher'
        ? viewer.profile?.aiHintOpenPromptMonthlyLimitRemaining
        : 5,
  };
}

type ViewerProviderProps = {
  children: ReactNode;
};

const emptyObject = {}; // For referential equality
export default function ViewerProvider({
  children,
}: ViewerProviderProps): ReactElement {
  const parentViewer = useContext(ViewerContext);
  // ideally, we would refactor this to accept an environment and allow this to be mocked everywhere,
  // but because this turns the viewer provider into a "asnyc blocking" component, tests start breaking everywhere.
  // this is a massive TODO and involves refactoring a large number of tests
  if (process.env.NODE_ENV === 'test') {
    return <MockViewerProvider>{children}</MockViewerProvider>;
  }
  // In the case where we mount a viewer provider inside another viewer provider
  // we want to make the child transparent to prevent duplicate network activity and blocking
  // of the view.
  // This happens in the textbook currently where we cannot disambiguate between teacher and student
  // roles at page start. Teacher views will always be in the spa context, so a viewer is available,
  // students are rendered conventionally
  if (parentViewer != null) return <>{children}</>;

  return (
    <Suspense fallback={null}>
      <ViewerProviderInner children={children} />
    </Suspense>
  );
}

const ViewerProviderInner = ({ children }: ViewerProviderProps) => {
  const props = useLazyLoadQuery<ViewerProviderQuery>(query, emptyObject);
  const payload = createViewerPayload(props);
  return (
    <>
      <SendViewerDataChangeToRN data={{ userId: payload.userId }} />
      <ViewerContext.Provider value={payload}>
        {children}
      </ViewerContext.Provider>
    </>
  );
};

function createFeatureFlags() {
  let featureFlags = {} as Record<
    (typeof FEATURE_FLAG_NAMES_FOR_TEST_MOCKS)[number],
    boolean
  >;
  for (const featureFlagName of FEATURE_FLAG_NAMES_FOR_TEST_MOCKS) {
    featureFlags[featureFlagName] = false;
  }
  return featureFlags;
}

function createFeatureFlagsV2() {
  let featureFlags = {} as Record<
    (typeof FEATURE_FLAG_V2_NAMES_FOR_TEST_MOCKS)[number],
    boolean
  >;
  for (const featureFlagName of FEATURE_FLAG_V2_NAMES_FOR_TEST_MOCKS) {
    featureFlags[featureFlagName] = false;
  }
  return featureFlags;
}

export function MockViewerProvider({
  featureFlags,
  children,
  hasAdminReport = false,
}: {
  children: ReactNode;
  // In test environments, we often want to change one flag from the default value.
  // We allow providing a partial feature flags object to support this use case.
  featureFlags?: Partial<FeatureFlags> | undefined;
  hasAdminReport?: boolean;
}) {
  // If we provide custom feature flags (which is typically just 1 or a few flag values)
  // we overwrite the default feature flags values using the supplied object.
  const mergedFeatureFlags = {
    ...createFeatureFlags(),
    ...featureFlags, // NOTE object spreading undefined is fine.
  };
  return (
    <ViewerContext.Provider
      value={{
        userId: 'User-123',
        userType: 'staff',
        userPk: 123,
        cohortType: 'STANDARD',
        userFirstName: 'FirstName-123',
        userLastName: 'LastName-123',
        userEmail: 'fake@email.com',
        createdAt: '2017-01-01T15:43:03.633158+00:00',
        profileId: 'Profile-123',
        lanternProfileId: null,
        selfReportedGrade: 0,
        focusSyllabusPk: 0,
        syllabusFocusId: 'Syllabus-1',
        isFloridaUser: false,
        isVirginiaUser: false,
        accountType: 'STAFF',
        isPartnerUser: false,
        isHeadTeacher: false,
        isSchoolAdmin: false,
        isTeacherStudent: false,
        hasAdminReport,
        hasMathspaceApp: true,
        canHaveAssignedTasks: false,
        isInClasses: false,
        countryCode: null,
        featureFlags: mergedFeatureFlags,
        role: 'Teacher',
        updateFeatureFlags: mutation({
          environment: publicEnvironment,
        }),
        featureFlagsV2: createFeatureFlagsV2(),
        isEmailVerified: false,
        learningFocus: null,
        learningFocusStatus: undefined,
        skillsBook: {
          shouldDefaultToSkillsTab: false,
        },
        aiHintOpenPromptMonthlyLimitRemaining: 5,
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
}

export function MockStudentViewerProvider({
  featureFlags = createFeatureFlags(),
  children,
}: {
  children: ReactNode;
  // In test environments, we often want to change one flag from the default value.
  // We allow providing a partial feature flags object to support this use case.
  featureFlags?: Partial<FeatureFlags>;
}) {
  // If we provide custom feature flags (which is typically just 1 or a few flag values)
  // we overwrite the default feature flags values using the supplied object.
  const mergedFeatureFlags = {
    ...createFeatureFlags(),
    ...featureFlags, // NOTE object spreading undefined is fine.
  };
  return (
    <ViewerContext.Provider
      value={{
        userId: 'User-123',
        userType: 'student.paid',
        userPk: 123,
        cohortType: 'STANDARD',
        userFirstName: 'FirstName-123',
        userLastName: 'LastName-123',
        userEmail: 'fake@email.com',
        createdAt: '2017-01-01T15:43:03.633158+00:00',
        profileId: 'Profile-123',
        lanternProfileId: null,
        selfReportedGrade: 0,
        focusSyllabusPk: 0,
        syllabusFocusId: 'Syllabus-1',
        isFloridaUser: false,
        isVirginiaUser: false,
        accountType: 'STUDENT',
        isPartnerUser: false,
        isHeadTeacher: false,
        isSchoolAdmin: false,
        isTeacherStudent: false,
        hasAdminReport: false,
        hasMathspaceApp: true,
        canHaveAssignedTasks: true,
        isInClasses: true,
        countryCode: null,
        featureFlags: mergedFeatureFlags,
        role: 'Student',
        updateFeatureFlags: mutation({
          environment: publicEnvironment,
        }),
        featureFlagsV2: createFeatureFlagsV2(),
        isEmailVerified: false,
        learningFocus: null,
        learningFocusStatus: 'UNSET',
        skillsBook: {
          shouldDefaultToSkillsTab: false,
        },
        aiHintOpenPromptMonthlyLimitRemaining: 5,
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
}
