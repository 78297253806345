import deepFreeze from 'deep-freeze';

export const breakPoints = Object.freeze({
  small: 480,
  medium: 768,
  large: 1024,
  extraLarge: 1280,
});

// TODO: remove once we drop the support for IE11
export const MEDIA_QUERY_TO_TARGET_IE11 =
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)';

export const shadowColors = Object.freeze({
  dropdownMenu: 'rgba(0, 0, 0, 0.16)',
  attachment: 'rgba(154, 154, 154, 0.3)',
  subscriptionCard: 'rgba(175, 175, 175, 0.34)',
  header: 'rgba(212, 215, 217, 0.27)',
});

export const fontFamily = Object.freeze({
  heading: 'Gilroy, sans-serif',
  body: '"Proxima Nova", Helvetica, Arial, sans-serif',
  ui: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
});

export const fontSize = Object.freeze({
  paymentModalTitle: 31,
  membershipDetailsData: 19,
  card: 16,
  cardHeader: 30,
  disclaimer: 12,
  errorMessage: 13,
  fieldGroupMain: 16,
  fieldGroupSub: 14,
  input: 15,
  label: 10,
  modalH1: 24,
  modalH2: 20,
  modalH3: 16,
  sectionTitle: 32,
  subsectionTitle: 15,
  tableCell: 14,
  topicHeading: 13,
  marqueeTitle: 32,
  marqueeSubtitle: 18,
  price: 32,
  menuLink: 16,
  menuHeading: 24,
  leaderboardName: 20,
  dashboardGreeting: 32,
  dashboardGreetingLarge: 50,
  xSmall: 12,
  small: 14,
  medium: 16,
  large: 20,
  xLarge: 26,
  xxLarge: 32,
  bannerTitle: 36,
  bannerTitleLarge: 48,
  bannerTitleSecondary: 26,
  bannerTitleSecondaryLarge: 40,
  taskListHeading: 26,
  taskListHeadingLarge: 38,
  notFoundTitle: 32,
  notFoundTitleMedium: 36,
  notFoundLead: 18,
  notFoundLeadSmall: 20,
  notFoundLeadMedium: 24,
  notFoundDescriptionMedium: 18,
  infoStepTitle: 22,
  infoStepNumber: 24,
  problemSelector: 18,
  headerTitle: 18,
  subtopicsListHeading: 26,
});

export const fontWeight = Object.freeze({
  light: 100,
  normal: 300,
  regular: 400,
  semibold: 600,
});

export const lineHeight = Object.freeze({
  body: 1.375,
  title: 1.25,
  heading: 1.125,
  subscriptionCard: 1.6,
});

export const borderRadiusUI = 4;
export const borderRadiusUILarge = 8;
export const borderRadiusUILarger = 12;
export const borderRadiusUIExtraLarge = 16;
export const borderRadiusUIXXLarge = 24;
export const borderRadiusCircle = 30;

export const inputHeight = 38;

export const transition = '0.15s ease';

export const dimensions = deepFreeze({
  // Currently only used in EBook but will be reused by other parts of the site
  container: {
    maxWidth: 780,
    margin: {
      default: 20,
      small: 50,
      medium: 75,
    },
  },
});

const MATHQUILL_PADDING_HORIZONTAL = 12;
const MATHQUILL_PADDING_VERTICAL = 9;

export const mathquillStyles = deepFreeze({
  padding: {
    // This value is exported separately, so that it can be used to apply
    // an offset to placeholders.
    horizontal: MATHQUILL_PADDING_HORIZONTAL,
    // This +/- 1 fudge factor is intended to accommodate for sloppy metrics
    // in Symbola.  We could try resolving this in the font file directly:
    // @see https://trello.com/c/xZAULbwc/
    shorthand: `${
      MATHQUILL_PADDING_VERTICAL + 1
    }px ${MATHQUILL_PADDING_HORIZONTAL}px ${MATHQUILL_PADDING_VERTICAL - 1}px`,
  },
});

// TODO: consolidate so that we don't have duplicate values
export const zIndex = Object.freeze({
  selectOptions: 40,
  overlayLayout: 101, // Must beat selectOptions and drawerAside
  flyout: 101, // must be below snackbar
  newFeaturesPopup: 101, // must be below snackbar
  snackbar: 102,
  handwritingPanelClose: 20,
  probabilityTreeMenu: 3, // TODO: remove once the old solve page is gone (this is to overlay the action buttons)
  problemSelector: 20,
  header: 2,
  // Previously we had this set to be higher than all other z-indexes so that it
  // was never covered by the Intercom chat widget. However, this meant that
  // popovers and tooltips were not able to be shown from inside of a modal. To
  // prevent regressions, we are setting this to be as high as we can while also
  // not covering popovers and tooltips. Then for the Intercom chat widget
  // itself, we're hiding it whenever the user clicks outside of it so that it
  // never covers modals, tooltips, etc.
  portal: 30,
  popoverOverlay: 40,
  popover: 41, // it is safe to assume is the same of the dropdownMenu
  dropdownMenuItem: 42,
  dropdownMenuSearch: 43, // dropdowns that are accompanied by a search input
  tooltip: 50,
  drawerAside: 100,
  textbookSelectorOverlay: 101, // Must beat drawerAside
  multiTextbookSearchModalOverlay: 101,
  catfaFloatingFooter: 2,
  plusButtonParticle: 1000, // this should be on top of everything
});

export const loadingIndicatorDelay = 200;

// New pattern for font styles

export const GILROY_FONT_FAMILY = 'Gilroy, sans-serif';
export const font = {
  gilroySmall: {
    fontFamily: GILROY_FONT_FAMILY,
    fontSize: 10,
    fontWeight: 800,
    lineHeight: '24px',
  },
  gilroyHeading: {
    fontFamily: GILROY_FONT_FAMILY,
    fontSize: 20,
    fontWeight: 800,
    lineHeight: '24px',
  },
  gilroyLargeHeading: {
    fontFamily: GILROY_FONT_FAMILY,
    fontSize: 24,
    fontWeight: 800,
    lineHeight: '26px',
  },
  gilroyLargerHeading: {
    fontFamily: GILROY_FONT_FAMILY,
    fontSize: 28,
    fontWeight: 800,
    lineHeight: '32px',
  },
  gilroyExtraLargeHeading: {
    fontFamily: GILROY_FONT_FAMILY,
    fontSize: 32,
    fontWeight: 800,
    lineHeight: '40px',
  },
} as const;
