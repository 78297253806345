/*global define */
define([
    'can',
    'react',
    'react-dom',
    'ms-components/MainMenu/MainMenuAdmin',
    'ms-utils/relay',
    'can/component',
    'can/map',
    'can/map/define',
], function (
    can,
    React,
    ReactDOM,
    MainMenu,
    relayUtils
) {
    'use strict';

    /**
     * CanJS Wrapper Component
     *
     * @name uc-main-menu
     * @example <uc-main-menu></uc-main-menu>
     */
    can.Component.extend({
        tag: 'uc-main-menu',
        template: '',
        viewModel: {
            define: {
                isOpen: { type: 'boolean', value: false },
                debug: { type: 'boolean', value: false },
            },
        },
        events: {
            /**
             * CanJS Lifecycle
             */
            inserted: function () {
                this.onClose = this.onClose.bind(this);

                this.render();
            },
            removed: function () {
                ReactDOM.unmountComponentAtNode(this.element[0]);
            },

            /**
             * Artificial Lifecycle
             *
             * (for supporting React)
             */
            ' {viewModel} isOpen change': function () {
                this.render();
            },
            onClose: function () {
                this.viewModel.attr('isOpen', false);
            },
            render: function () {
                var props = this.viewModel.serialize();

                props.onClose = this.onClose;
                props.relayEnvironment = relayUtils.publicEnvironment

                ReactDOM.render(
                    React.createElement(MainMenu.default, props),
                    this.element[0]
                );
            },
        },
    });
});
