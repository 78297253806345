/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type featureFlagsV2 = {
    readonly canAccessNewDistrictReport: boolean;
    readonly canAccessNewDistrictReportImpact: boolean;
    readonly canAccessNewDistrictReportSchoolLevel: boolean;
    readonly canAccessNewDistrictReportTemplates: boolean;
    readonly canAssignDiscoveryCheckIn: boolean;
    readonly canChooseSkillCheckin: boolean;
    readonly canEnableTemplatesNavV2: boolean;
    readonly canPreviewProblemEventDataInSkills: boolean;
    readonly canvasEnableGradePassback: boolean;
    readonly checkinsEnableGrowthPeriodFilter: boolean;
    readonly classReportsEnableCheckInActivityDetail: boolean;
    readonly enableAiExplainQuestion: boolean;
    readonly enableAiMultimodalAnnotations: boolean;
    readonly enableAiNonCorrectHints: boolean;
    readonly enableAiOpenPrompt: boolean;
    readonly enableAiOpenPromptV2: boolean;
    readonly enableAiPremiumTrial: boolean;
    readonly enableAiStaticHintPrompts: boolean;
    readonly enableAiSuggestQuestions: boolean;
    readonly enableAiTextbookHintContext: boolean;
    readonly enableCATFAv2AssignCheckin: boolean;
    readonly enableCollapseQuestionsByDefault: boolean;
    readonly enableDefaultActivityReport: boolean;
    readonly enableDefaultPlanner: boolean;
    readonly enableFeatureFlagsV2: boolean;
    readonly enableMTSInCATFA: boolean;
    readonly enableOpenWorkbookFinishQuestion: boolean;
    readonly enableOptInDiscoveryCheckIn: boolean;
    readonly enableSilentWorkbook: boolean;
    readonly enableTextbookFilterDifficulty: boolean;
    readonly gamificationEnableChallengesDaily: boolean;
    readonly gamificationEnableCustomisation: boolean;
    readonly gamificationEnableLeaderboardDock: boolean;
    readonly gamificationEnableLeaderboardNotification: boolean;
    readonly gamificationEnableLeaderboardTab: boolean;
    readonly gamificationEnableLiveModePromote: boolean;
    readonly gamificationEnablePointsPromote: boolean;
    readonly gamificationEnablePointsQuestionStreak: boolean;
    readonly gamificationEnablePointsUpdate: boolean;
    readonly gamificationEnableTugOfWar: boolean;
    readonly globalSearchEnableMVP: boolean;
    readonly hasDismissedOnboardingModalInAdaptiveIntro: boolean;
    readonly hasEnabledAccessibilityMode: boolean;
    readonly hasNewWorkbookHintsUxTurnedOn: boolean;
    readonly hasStudentAppSpa: boolean;
    readonly hasUpdatedCorrectStepIcons: boolean;
    readonly hasUpdatedQuestionsView: boolean;
    readonly insightsEnableLearningHighlights: boolean;
    readonly perfEnableModelDataLoaders: boolean;
    readonly printEnablePageBreaksAfterPTs: boolean;
    readonly problemContentsShortcutKillswitchEnabled: boolean;
    readonly showLoginV2: boolean;
    readonly showLunarNewYearEvent: boolean;
    readonly showMaintenanceBanner: boolean;
    readonly showSkillsBookByDefault: boolean;
    readonly showSkillsBookGridView: boolean;
    readonly showWorkbookOnboarding: boolean;
    readonly showXmasEvent: boolean;
    readonly templatesEnableDistrictSharingPermissions: boolean;
    readonly templatesEnableDualWriteTreeStructure: boolean;
    readonly templatesEnableDuplicate: boolean;
    readonly templatesEnableEditTemplateQuestions: boolean;
    readonly templatesEnableNavV2: boolean;
    readonly templatesEnableSubfolders: boolean;
    readonly textbookDisableSecondaryAssignCTA: boolean;
    readonly useSanaConfidenceInterval: boolean;
    readonly useSanaCorrelationsUpdator: boolean;
    readonly useSanaTimeDependentUncertaintyInjection: boolean;
    readonly workbookAiEnableGpt4o20240513: boolean;
    readonly workbookAiEnableGpt4o20240513CombinedPrompt: boolean;
    readonly workbookAiEnableGpt4turbo20240409: boolean;
    readonly workbookAiEnableGpt4turbo20240409CombinedPrompt: boolean;
    readonly workbookEnableIncorrectStepPrompt: boolean;
    readonly workbookEnableNudgeHint: boolean;
    readonly workbookEnableNudgeStepCorrectSpam: boolean;
    readonly workbookEnableNudgeVideo: boolean;
    readonly workbookEnableNudgeWorkedExample: boolean;
};
export type featureFlagsV2$data = featureFlagsV2;
export type featureFlagsV2$key = {
    readonly " $data"?: featureFlagsV2$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"featureFlagsV2">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "featureFlagsV2",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportImpact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportSchoolLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportTemplates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAssignDiscoveryCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canChooseSkillCheckin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEnableTemplatesNavV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canPreviewProblemEventDataInSkills",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canvasEnableGradePassback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkinsEnableGrowthPeriodFilter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "classReportsEnableCheckInActivityDetail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiExplainQuestion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiMultimodalAnnotations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiNonCorrectHints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiOpenPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiOpenPromptV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiPremiumTrial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiStaticHintPrompts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiSuggestQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiTextbookHintContext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableCATFAv2AssignCheckin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableCollapseQuestionsByDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableDefaultActivityReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableDefaultPlanner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableFeatureFlagsV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableMTSInCATFA",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableOpenWorkbookFinishQuestion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableOptInDiscoveryCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableSilentWorkbook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableTextbookFilterDifficulty",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableChallengesDaily",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableCustomisation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardDock",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardTab",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLiveModePromote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsPromote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsQuestionStreak",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableTugOfWar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalSearchEnableMVP",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDismissedOnboardingModalInAdaptiveIntro",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEnabledAccessibilityMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNewWorkbookHintsUxTurnedOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasStudentAppSpa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUpdatedCorrectStepIcons",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUpdatedQuestionsView",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insightsEnableLearningHighlights",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "perfEnableModelDataLoaders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printEnablePageBreaksAfterPTs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problemContentsShortcutKillswitchEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showLoginV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showLunarNewYearEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMaintenanceBanner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showSkillsBookByDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showSkillsBookGridView",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showWorkbookOnboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showXmasEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatesEnableDistrictSharingPermissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatesEnableDualWriteTreeStructure",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatesEnableDuplicate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatesEnableEditTemplateQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatesEnableNavV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templatesEnableSubfolders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbookDisableSecondaryAssignCTA",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSanaConfidenceInterval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSanaCorrelationsUpdator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSanaTimeDependentUncertaintyInjection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookAiEnableGpt4o20240513",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookAiEnableGpt4o20240513CombinedPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookAiEnableGpt4turbo20240409",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookAiEnableGpt4turbo20240409CombinedPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableIncorrectStepPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableNudgeHint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableNudgeStepCorrectSpam",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableNudgeVideo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableNudgeWorkedExample",
      "storageKey": null
    }
  ],
  "type": "FeatureFlagsV2",
  "abstractKey": null
};
(node as any).hash = '045216379caf854663f88ef2f1834908';
export default node;
