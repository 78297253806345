import { init } from 'ramda';

/**
 * No Op.
 *
 * This function is a well-typed do-nothing helper.  It is intended primarily
 * to clarify and reduce boilerplate in callsites.  It can also provide a small
 * performance win.
 * @param {*} anything - anything
 * @returns {undefined}
 */
export const noop = (..._xs: Array<any>): void => {}; // eslint-disable-line no-unused-vars

// !!! This MUST be kept in sync with msproblem/accounts/constants.py !!!
/* eslint-disable quote-props */
export const USER_TYPES = Object.freeze({
  inactive: 'inactive',
  invalid: 'invalid',
  service: 'service',
  staff: 'staff',
  'student.canvas': 'student.canvas',
  'student.classlink': 'student.classlink',
  'student.clever': 'student.clever',
  'student.expired': 'student.expired',
  'student.demo': 'student.demo',
  'student.euka': 'student.euka',
  'student.freemium': 'student.freemium',
  'student.pearson': 'student.pearson',
  'student.school_paid': 'student.school_paid',
  'student.schoology': 'student.schoology',
  'student.schoolsplp': 'student.schoolsplp',
  'student.oneroster': 'student.oneroster',
  'student.student_paid': 'student.student_paid',
  'student.student_paid.unpaid': 'student.student_paid.unpaid',
  'teacher.canvas': 'teacher.canvas',
  'teacher.classlink': 'teacher.classlink',
  'teacher.clever': 'teacher.clever',
  'teacher.demo': 'teacher.demo',
  'teacher.expired': 'teacher.expired',
  'teacher.freemium': 'teacher.freemium',
  'teacher.pearson': 'teacher.pearson',
  'teacher.school_paid': 'teacher.school_paid',
  'teacher.schoology': 'teacher.schoology',
  'teacher.schoolsplp': 'teacher.schoolsplp',
  'teacher.oneroster': 'teacher.oneroster',
  'teacher.student_paid': 'teacher.student_paid',
  'user.external_application': 'user.external_application',
});
/* eslint-enable quote-props */

export type UserType = keyof typeof USER_TYPES;

const trimTrailingSlash = (str: string) => str.replace(/\/$/, '');

export const appendToPath = (
  path: string,
  segment: string,
  queryString: string = '',
) => `${trimTrailingSlash(path)}/${segment}${queryString}`;

export const replaceLastFromPath = (
  path: string,
  segment: string,
  queryString: string = '',
) =>
  `${init(trimTrailingSlash(path).split('/'))
    .concat(segment)
    .join('/')
    .concat(queryString)}`;
