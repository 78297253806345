import { find, propEq } from 'ramda';

import type { FeatureFlags } from 'ms-helpers/Viewer/ViewerProvider';
import type { LinkGroup } from 'ms-ui-primitives/Menu';
import type { UserType } from 'ms-utils/misc';

import type { Route } from '.';

const urlFor = (routeName: string, routes: ReadonlyArray<Route>): string => {
  const route = find(propEq('name', routeName), routes);
  return route ? route.url : '#link-not-loaded';
};

function textbookLink({
  disabled = false,
  routes,
}: {
  disabled?: boolean;
  routes: ReadonlyArray<Route>;
}) {
  return [
    {
      disabled,
      linkName: 'Textbook',
      url: urlFor('textbook', routes),
    },
  ];
}

const linkGroupsForUserType = (
  userType: UserType,
  routes: ReadonlyArray<Route>,
  featureFlags: FeatureFlags,
  debug: boolean | undefined,
): ReadonlyArray<LinkGroup> => {
  switch (userType) {
    case 'inactive':
    case 'invalid':
    case 'user.external_application':
      return [
        [
          { linkName: 'Settings', url: urlFor('accounts_settings', routes) },
          { linkName: 'Logout', url: urlFor('accounts_logout', routes) },
        ],
      ];
    case 'student.expired':
    case 'student.student_paid.unpaid':
      return [
        [
          {
            disabled: true,
            linkName: 'Dashboard',
            url: urlFor('accounts_dashboard_redirect', routes),
          },
          {
            disabled: true,
            linkName: 'Topics',
            url: urlFor('topics', routes),
          },
          ...textbookLink({ disabled: true, routes }),
          {
            disabled: true,
            linkName: 'Join a class',
            url: urlFor('study_join_class', routes),
          },
        ],
        [
          {
            disabled: true,
            linkName: 'Settings',
            url: urlFor('accounts_settings', routes),
          },
          { linkName: 'Logout', url: urlFor('accounts_logout', routes) },
        ],
      ];
    case 'service':
    case 'staff':
      return [
        [
          {
            linkName: 'Admin Dashboard',
            url: urlFor('accounts_dashboard_redirect', routes),
          },
        ],
        [
          {
            linkName: 'Teacher Dashboard',
            url: urlFor('nte_dashboard', routes),
          },
          { linkName: 'Topics', url: urlFor('topics', routes) },
          ...textbookLink({ routes }),
        ],
        [
          { linkName: 'Debug', url: urlFor('debug', routes) },
          { linkName: 'GraphQL', url: urlFor('graphQL', routes) },
          {
            linkName: 'JSX Content Editor',
            url: 'https://editor.mathspace.co/',
          },
          ...(featureFlags.canEditLanternQuestions
            ? [
                {
                  linkName: 'Diagnostic Questions Editor',
                  url: urlFor('diagnostic_questions_editor', routes),
                },
              ]
            : []),
          ...(debug
            ? [
                {
                  linkName: 'Storybook Dev',
                  url: urlFor('storybook_dev', routes),
                },
              ]
            : []),
          {
            linkName: 'Storybook',
            url: urlFor('storybook', routes),
          },
        ],
        [
          { linkName: 'Settings', url: urlFor('accounts_settings', routes) },
          {
            linkName: 'Help',
            url: urlFor('help', routes),
            openInNewWindow: true,
          },
          { linkName: 'Logout', url: urlFor('accounts_logout', routes) },
        ],
      ];
    case 'student.canvas':
    case 'student.classlink':
    case 'student.clever':
    case 'student.euka':
    case 'student.schoology':
    case 'student.schoolsplp':
    case 'student.oneroster':
      return [
        [
          {
            linkName: 'Dashboard',
            url: urlFor('accounts_dashboard_redirect', routes),
          },
          { linkName: 'Tasks', url: urlFor('tasks', routes) },
          { linkName: 'Topics', url: urlFor('topics', routes) },
          ...textbookLink({ routes }),
        ],
        [
          { linkName: 'Settings', url: urlFor('accounts_settings', routes) },
          { linkName: 'Logout', url: urlFor('accounts_logout', routes) },
        ],
      ];
    case 'student.demo':
    case 'student.freemium':
    case 'student.pearson':
    case 'student.school_paid':
    case 'student.student_paid':
      return [
        [
          {
            linkName: 'Dashboard',
            url: urlFor('accounts_dashboard_redirect', routes),
          },
          { linkName: 'Tasks', url: urlFor('tasks', routes) },
          { linkName: 'Topics', url: urlFor('topics', routes) },
          ...textbookLink({ routes }),
          { linkName: 'Join a class', url: urlFor('study_join_class', routes) },
        ],
        [
          { linkName: 'Settings', url: urlFor('accounts_settings', routes) },
          { linkName: 'Logout', url: urlFor('accounts_logout', routes) },
        ],
      ];
    case 'teacher.expired':
      return [
        [
          {
            disabled: true,
            linkName: 'Home',
            url: urlFor('accounts_dashboard_redirect', routes),
          },
          ...textbookLink({ disabled: true, routes }),
        ],
        [
          { linkName: 'Settings', url: urlFor('accounts_settings', routes) },
          {
            linkName: 'Help',
            url: urlFor('help', routes),
            openInNewWindow: true,
          },
          { linkName: 'Logout', url: urlFor('accounts_logout', routes) },
        ],
      ];
    case 'teacher.pearson':
    case 'teacher.canvas':
    case 'teacher.classlink':
    case 'teacher.clever':
    case 'teacher.demo':
    case 'teacher.freemium':
    case 'teacher.school_paid':
    case 'teacher.student_paid':
    case 'teacher.schoology':
    case 'teacher.schoolsplp':
    case 'teacher.oneroster':
      return [
        [
          {
            linkName: 'Home',
            url: urlFor('accounts_dashboard_redirect', routes),
          },
          { linkName: 'Topics', url: urlFor('topics', routes) },
          ...textbookLink({ routes }),
        ],
        [
          { linkName: 'Settings', url: urlFor('accounts_settings', routes) },
          {
            linkName: 'Help',
            url: urlFor('help', routes),
            openInNewWindow: true,
          },
          { linkName: 'Logout', url: urlFor('accounts_logout', routes) },
        ],
      ];
    default:
      throw Error(
        `Unknown user type ${userType} passed to linkGroupsForUserType`,
      );
  }
};

export default linkGroupsForUserType;
