import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import ViewerProvider from 'ms-helpers/Viewer/ViewerProvider';
import type { UserType } from 'ms-utils/misc';
import { menuRoutes } from 'ms-utils/misc/config';
import { accountsAvatarUrl } from 'ms-utils/urls';

import MainMenuPresentational from './MainMenuPresentational';
import type { MainMenu_viewer$key } from './__generated__/MainMenu_viewer.graphql';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onExitAnimationEnd?: (() => void) | undefined;
  // TODO change to | null once backwards compat with Flow is no
  // longer required as relay-generated Flow types use nullable ?
  // which includes undefined, but TS types don't. Remove
  // narrowedViewer in the body at the same time.
  viewer: MainMenu_viewer$key | null | undefined;
  debug?: boolean | undefined;
};
export default function MainMenu({
  isOpen,
  onClose,
  onExitAnimationEnd,
  viewer,
  debug,
}: Props) {
  // TODO remove this once viewer is typed as MainMenu_viewer$key | null
  // We need remove undefined from the type as useFragment doesn't allow
  // it, and we can't conditionally bail out here as useFragment is a hook
  const narrowedViewer = viewer ?? null;
  const viewerData = useFragment(
    graphql`
      fragment MainMenu_viewer on User {
        firstName
        userType
        avatar
        syllabusFocus {
          pk
        }
      }
    `,
    narrowedViewer,
  );
  if (viewerData === null) return null;
  // Our GraphQL returns a string for userType but we seem to have tried
  // to explicitly type the user types by extracting all the values from
  // msproblem/accounts/constants.py and encoding them in the client.
  // See local_modules/ms-utils/misc/index
  //
  // TODO fix our graphql to return an enum of these actual values so we
  // don't have the possibility of the client getting out-of-sync with the
  // server. Then remove this unsafe coercion here.
  const userType = viewerData.userType as UserType;
  return (
    <ViewerProvider>
      <MainMenuPresentational
        firstName={viewerData.firstName}
        userType={userType}
        avatarUrl={viewerData.avatar}
        routes={menuRoutes}
        accountsAvatarUrl={accountsAvatarUrl}
        isOpen={isOpen}
        onClose={onClose}
        onExitAnimationEnd={onExitAnimationEnd}
        debug={debug}
      />
    </ViewerProvider>
  );
}
