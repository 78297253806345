import { css, StyleSheet } from 'aphrodite';
import type { ReactElement } from 'react';

import { fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { Anchor } from 'ms-ui-primitives/Link';
import { onHover } from 'ms-utils/emotion';

type Props = {
  label: string;
  url: string;
  isDisabled?: boolean | undefined;
  decoration: ReactElement<any> | null | undefined;
  openInNewWindow?: boolean | undefined;
};

const Item = ({
  label,
  url,
  isDisabled,
  decoration,
  openInNewWindow,
}: Props) => {
  const color = isDisabled ? colors.dustyGray : colors.white;
  const styles = StyleSheet.create({
    listItem: {
      listStyleType: 'none',
      padding: 0,
      ...onHover({
        backgroundColor: isDisabled ? 'none' : colors.mako,
      }),
    },
    link: {
      alignItems: 'center',
      color,
      display: 'flex',
      height: 62,
      padding: '0 25px',
      fontSize: fontSize.menuLink,
      fontWeight: fontWeight.normal,
      cursor: isDisabled ? 'default' : 'pointer',
      ...onHover({
        // to override default link color in hover
        color,
      }),
    },
    linkLabel: {
      lineHeight: 1,
      paddingRight: 15,
    },
  });

  const newWindowProps = openInNewWindow
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  return (
    <li className={css(styles.listItem)}>
      <Anchor
        href={isDisabled ? undefined : url}
        aphroditeStyles={[styles.link]}
        {...newWindowProps}
      >
        <span className={css(styles.linkLabel)}>{label}</span>
        {decoration}
      </Anchor>
    </li>
  );
};

export default Item;
