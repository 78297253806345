import { StyleSheet, css } from 'aphrodite';
import type { ReactElement } from 'react';

import { lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import type { UserType } from 'ms-utils/misc';

import Header from './Header';
import Item from './Item';

export type Link = {
  linkName: string;
  url: string;
  disabled?: boolean;
  decoration?: ReactElement<any>;
  openInNewWindow?: boolean;
};

export type LinkGroup = ReadonlyArray<Link>;

type Props = {
  firstName: string;
  userType: UserType;
  avatarUrl: string;
  accountsAvatarUrl: string;
  linkGroups: ReadonlyArray<LinkGroup>;
};

const styles = StyleSheet.create({
  container: {
    background: colors.mako93,
    width: 280,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    lineHeight: lineHeight.body,
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0,
  },
  divider: {
    borderBottom: `1px solid ${colors.boulder}`,
  },
  spacer: {
    flexGrow: 1,
  },
});

const Divider = () => <div className={css(styles.divider)} />;

const Menu = ({
  firstName,
  userType,
  avatarUrl,
  accountsAvatarUrl,
  linkGroups,
}: Props) => (
  <div className={css(styles.container)}>
    <Header
      avatarUrl={avatarUrl}
      accountsAvatarUrl={accountsAvatarUrl}
      firstName={firstName}
      userType={userType}
    />
    <nav role="navigation">
      <ul className={css(styles.list)}>
        {linkGroups.map((linkGroup, index) => (
          <div key={index}>
            <Divider />
            {linkGroup.map(
              ({ linkName, url, disabled, decoration, openInNewWindow }) => (
                <Item
                  key={linkName}
                  label={linkName}
                  url={url}
                  isDisabled={disabled}
                  decoration={decoration}
                  openInNewWindow={openInNewWindow}
                />
              ),
            )}
          </div>
        ))}
      </ul>
    </nav>
  </div>
);

export default Menu;
