// DO NOT MODIFY THIS FILE
// Generated by bin/generate_feature_flags_v2.py
import { graphql } from 'react-relay';

graphql`
  fragment featureFlagsV2 on FeatureFlagsV2 @relay(mask: false) {
    canAccessNewDistrictReport
    canAccessNewDistrictReportImpact
    canAccessNewDistrictReportSchoolLevel
    canAccessNewDistrictReportTemplates
    canAssignDiscoveryCheckIn
    canChooseSkillCheckin
    canEnableTemplatesNavV2
    canPreviewProblemEventDataInSkills
    canvasEnableGradePassback
    checkinsEnableGrowthPeriodFilter
    classReportsEnableCheckInActivityDetail
    enableAiExplainQuestion
    enableAiMultimodalAnnotations
    enableAiNonCorrectHints
    enableAiOpenPrompt
    enableAiOpenPromptV2
    enableAiPremiumTrial
    enableAiStaticHintPrompts
    enableAiSuggestQuestions
    enableAiTextbookHintContext
    enableCATFAv2AssignCheckin
    enableCollapseQuestionsByDefault
    enableDefaultActivityReport
    enableDefaultPlanner
    enableFeatureFlagsV2
    enableMTSInCATFA
    enableOpenWorkbookFinishQuestion
    enableOptInDiscoveryCheckIn
    enableSilentWorkbook
    enableTextbookFilterDifficulty
    gamificationEnableChallengesDaily
    gamificationEnableCustomisation
    gamificationEnableLeaderboardDock
    gamificationEnableLeaderboardNotification
    gamificationEnableLeaderboardTab
    gamificationEnableLiveModePromote
    gamificationEnablePointsPromote
    gamificationEnablePointsQuestionStreak
    gamificationEnablePointsUpdate
    gamificationEnableTugOfWar
    globalSearchEnableMVP
    hasDismissedOnboardingModalInAdaptiveIntro
    hasEnabledAccessibilityMode
    hasNewWorkbookHintsUxTurnedOn
    hasStudentAppSpa
    hasUpdatedCorrectStepIcons
    hasUpdatedQuestionsView
    insightsEnableLearningHighlights
    perfEnableModelDataLoaders
    printEnablePageBreaksAfterPTs
    problemContentsShortcutKillswitchEnabled
    showLoginV2
    showLunarNewYearEvent
    showMaintenanceBanner
    showSkillsBookByDefault
    showSkillsBookGridView
    showWorkbookOnboarding
    showXmasEvent
    templatesEnableDistrictSharingPermissions
    templatesEnableDualWriteTreeStructure
    templatesEnableDuplicate
    templatesEnableEditTemplateQuestions
    templatesEnableNavV2
    templatesEnableSubfolders
    textbookDisableSecondaryAssignCTA
    useSanaConfidenceInterval
    useSanaCorrelationsUpdator
    useSanaTimeDependentUncertaintyInjection
    workbookAiEnableGpt4o20240513
    workbookAiEnableGpt4o20240513CombinedPrompt
    workbookAiEnableGpt4turbo20240409
    workbookAiEnableGpt4turbo20240409CombinedPrompt
    workbookEnableIncorrectStepPrompt
    workbookEnableNudgeHint
    workbookEnableNudgeStepCorrectSpam
    workbookEnableNudgeVideo
    workbookEnableNudgeWorkedExample
  }
`;
export const FEATURE_FLAG_V2_NAMES_FOR_TEST_MOCKS = [
  'canAccessNewDistrictReport',
  'canAccessNewDistrictReportImpact',
  'canAccessNewDistrictReportSchoolLevel',
  'canAccessNewDistrictReportTemplates',
  'canAssignDiscoveryCheckIn',
  'canChooseSkillCheckin',
  'canEnableTemplatesNavV2',
  'canPreviewProblemEventDataInSkills',
  'canvasEnableGradePassback',
  'checkinsEnableGrowthPeriodFilter',
  'classReportsEnableCheckInActivityDetail',
  'enableAiExplainQuestion',
  'enableAiMultimodalAnnotations',
  'enableAiNonCorrectHints',
  'enableAiOpenPrompt',
  'enableAiOpenPromptV2',
  'enableAiPremiumTrial',
  'enableAiStaticHintPrompts',
  'enableAiSuggestQuestions',
  'enableAiTextbookHintContext',
  'enableCATFAv2AssignCheckin',
  'enableCollapseQuestionsByDefault',
  'enableDefaultActivityReport',
  'enableDefaultPlanner',
  'enableFeatureFlagsV2',
  'enableMTSInCATFA',
  'enableOpenWorkbookFinishQuestion',
  'enableOptInDiscoveryCheckIn',
  'enableSilentWorkbook',
  'enableTextbookFilterDifficulty',
  'gamificationEnableChallengesDaily',
  'gamificationEnableCustomisation',
  'gamificationEnableLeaderboardDock',
  'gamificationEnableLeaderboardNotification',
  'gamificationEnableLeaderboardTab',
  'gamificationEnableLiveModePromote',
  'gamificationEnablePointsPromote',
  'gamificationEnablePointsQuestionStreak',
  'gamificationEnablePointsUpdate',
  'gamificationEnableTugOfWar',
  'globalSearchEnableMVP',
  'hasDismissedOnboardingModalInAdaptiveIntro',
  'hasEnabledAccessibilityMode',
  'hasNewWorkbookHintsUxTurnedOn',
  'hasStudentAppSpa',
  'hasUpdatedCorrectStepIcons',
  'hasUpdatedQuestionsView',
  'insightsEnableLearningHighlights',
  'perfEnableModelDataLoaders',
  'printEnablePageBreaksAfterPTs',
  'problemContentsShortcutKillswitchEnabled',
  'showLoginV2',
  'showLunarNewYearEvent',
  'showMaintenanceBanner',
  'showSkillsBookByDefault',
  'showSkillsBookGridView',
  'showWorkbookOnboarding',
  'showXmasEvent',
  'templatesEnableDistrictSharingPermissions',
  'templatesEnableDualWriteTreeStructure',
  'templatesEnableDuplicate',
  'templatesEnableEditTemplateQuestions',
  'templatesEnableNavV2',
  'templatesEnableSubfolders',
  'textbookDisableSecondaryAssignCTA',
  'useSanaConfidenceInterval',
  'useSanaCorrelationsUpdator',
  'useSanaTimeDependentUncertaintyInjection',
  'workbookAiEnableGpt4o20240513',
  'workbookAiEnableGpt4o20240513CombinedPrompt',
  'workbookAiEnableGpt4turbo20240409',
  'workbookAiEnableGpt4turbo20240409CombinedPrompt',
  'workbookEnableIncorrectStepPrompt',
  'workbookEnableNudgeHint',
  'workbookEnableNudgeStepCorrectSpam',
  'workbookEnableNudgeVideo',
  'workbookEnableNudgeWorkedExample',
] as const;
