import type { UserType } from 'ms-utils/misc';

const userTypeToDisplay = (userType: UserType): string => {
  switch (userType) {
    case 'inactive':
    case 'invalid':
    case 'user.external_application':
    case 'student.expired':
    case 'student.student_paid.unpaid':
    case 'teacher.expired':
      return '';
    case 'staff':
      return 'Mathspace Staff';
    case 'service':
      return 'Service';
    case 'student.canvas':
    case 'student.classlink':
    case 'student.clever':
    case 'student.demo':
    case 'student.freemium':
    case 'student.euka':
    case 'student.pearson':
    case 'student.school_paid':
    case 'student.schoology':
    case 'student.schoolsplp':
    case 'student.oneroster':
    case 'student.student_paid':
      return 'Student';
    case 'teacher.canvas':
    case 'teacher.classlink':
    case 'teacher.clever':
    case 'teacher.demo':
    case 'teacher.freemium':
    case 'teacher.pearson':
    case 'teacher.school_paid':
    case 'teacher.schoology':
    case 'teacher.schoolsplp':
    case 'teacher.oneroster':
    case 'teacher.student_paid':
      return 'Teacher';
    default:
      throw Error(`Unknown user type ${userType} passed to userTypeToDisplay`);
  }
};

export default userTypeToDisplay;
