// Used for enforcing exhaustive switches. A poor man's version
// of exhaustive pattern matching.
//
// switch (value) {
//   case 1: return 'blah';
//   default:
//     assertUnreachable(value);
// }

import type React from 'react';

//
export function assertUnreachable(value: never, errorMessage?: string): never {
  throw new Error(
    errorMessage ??
      `Exhaustiveness check failed at runtime. Received: ${value}`,
  );
}

export function assert(
  condition: boolean,
  message?: string,
): asserts condition {
  if (!condition) {
    throw new Error(
      `Assertion failed${message !== undefined ? `: ${message}` : ''}`,
    );
  }
}

// In JavaScript there is no first class Optional type, but there is a "poor mans"
// version of an Optional type that arises in the language with collection types.
//
// Let's consider how a good language like Swift models it first so we can compare to
// the JavaScript situation:
//
// var dict: [String: Int] = ["key1": 1, "key2": 2]
// let value1 = dict["key1"]         // value1 is of type Optional<Int>
// let value2 = dict["nonexistent"]  // value2 is of type Optional<Int>
//
// To work with value1/2 we need to expliclty handle the case Optional.none case,
// since there may not be an actual Int value to work with.
//
// If we know statically (or we think we know) that a value will never be Optional.none
// (like in the value1 case above), Swift has a ! operator similar to Typescript.
//
// let value1 = dict["key1"]!  // value1 is of type Int
//
// However, if you messed up and dict["key1"] produces Optional.none at runtime, you
// will get an exception since your statically asserted invariant was violated (you
// clearly didn't actually understand how your code could work at runtime).
//
// Let's compare this situation to Typescript (with noUncheckedIndexedAccess flag on):
//
// var dict: { [key: string]: number } = { "key1": 1, "key2": 2 }
// let value1 = dict["key1"]         // value1 is of type number | undefined
// let value2 = dict["nonexistent"]  // value2 is of type number | undefined
//
// let value1 = dict["key1"]!  // value1 is of type number
//
// Two things to note: 1) You can see that the way an "Optional" type is modelled
// as T | undefined. This shows up when accessing collections like arrays, objects,
// and so forth. 2) The ! operator is the same as in Swift, but the difference is
// that if you mess up you will not get an exception, your program will happily
// continue executing and this unexpectedly undefined value will propagate through
// your program and blow something up somewhere else, or at some distant time in
// the future. No good.
//
// In the above example if we go:
// let value2 = dict["nonexistent"]!
//
// Then value2 is of type number, but its runtime value is undefined. Yikes.
//
// Now we can understand the purpose of this unwrap function. It serves the same
// purpose as the ! operator, but does so with the same behaviour as in Swift.
// If, in the above example, we tried to go unwrap(value2) we would get an exception.
//
// Notably, it is *stricter* than Typescript's ! operator (which actually coerces
// T | null | undefined -> T). This is NOT what we want as null is not used in
// core collection types to model non-existence.
//
// Lastly the naming is inspired by Rust, which force unwraps optionals using
// someOptional.unwrap().
export function unwrap<T>(value: T): T & ({} | null) {
  if (value === undefined) {
    throw new UnwrapError(
      `Fatal error: Unexpectedly found undefined while unwrapping an "Optional" value`,
    );
  }
  return value;
}

class UnwrapError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnwrapError';
  }
}

// This is the missing counterpart of Readonly<T> in the standard library.
export type Writable<T> = {
  -readonly [P in keyof T]: T[P];
};

// Type guard for determining if a value is not null.
// Most frequent use case is to filter our null values from arrays.
// Note that this is explicitly only a filter for null values, not
// undefined values, and should not be modified to also filter out
// undefined values.
export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;
// Typescript type hints are often shitty when defining compound types.
// For example, if you have a type like:
//
// type Data = Record<SomeKey, number> & { x: string }
//
// When you hover Data, it just shows what's on the RHS. But what we
// really want to see is the "expanded type". Eg. if SomeKey is 'a' | 'b',
// then we want the type hint to be
//
// { a: number, b: number, x: string }
//
// This type is used to change the type so that it's "expanded type" is
// shown in the type hints instead. This tends to be much more useful
// as you don't have to go chasing down all the internal types definitions
// to manually expand the type in order to know how to satisfy the type.
// This is especially useful the more complex "compound type" is
export type Expand<T> = { [P in keyof T]: T[P] } | never;

// For some object type, produces a union of the value types for
// that object.
//
// It means that rather than doing this:
// typeof someObject[keyof typeof someObject];
//
// We can just do this:
// Values<typeof someObject>;
export type Values<T> = T[keyof T];

// Typescript doesn't infer correct types for the output of Object.entries()
// This type utility can be used for type casting to correct the output types
export type Entries<T> = [keyof T, Values<T>][];

export type NonEmptyArray<T> = [T, ...T[]];

// Omit does not work if you have a union of object types where you want
// to omit a particular key from each object in the union. This type
// utility does.
export type OmitForUnion<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

// Pick does not work if you have a union of object types where you want
// to omit a particular key from each object in the union. This type
// utility does.
export type PickForUnion<T, K extends keyof T> = T extends any
  ? Pick<T, K>
  : never;

export function isNotNullOrUndefined<T>(
  value: T | null | undefined,
): value is T {
  return value !== null && value !== undefined;
}

// Object.keys() returns a string[]. While this is technically correct
// from a soundness perspective, it is very annoying for how we commonly
// use Object.keys() in practice.
//
// For example, if we have an immutable data record like this:
//
// const data = Object.freeze({ a: 1, b: 2, c: 3 });
//
// We often want to map over the keys:
//
// Object.keys(data).map(key => ...))
//
// But key is of type string, not 'a' | 'b' | 'c'. Which often leads
// to cumbersome type casting (eg. key as 'a' | 'b' | 'c').
//
// So why is string[] technically correct here? Because, unlike Flow,
// Typescript has no notion of *exact* objects. `data` has the type
// { a: number; b: number: c: number }, but this is totally valid:
//
// const moreKeys = { a: 1, b: 2, c: 3, d: 4 };
// const data2: { a: number; b: number; c: number } = moreKeys;
//
// In this case Object.keys(data2) will also include 'd' in the array,
// even though the type of data2 is { a: number; b: number: c: number }.
// Therefore, if Object.keys() returned 'a' | 'b' | 'c'[], it would be
// completely incorrect. Hence .keys() returns string[].
//
// This utility function is an *unsafe* version of Object.keys(), since
// it will return the type 'a' | 'b' | 'c'[]. It should only be used in
// situations where you have strong guarantees as to the *exact* shape
// of the object (such as the first example above).
export const unsafeKeys = Object.keys as <T>(o: T) => AsObjectKeys<keyof T>[];
// This type is used to convert the types produced by `keyof T` to
// the type that Object.keys will actually produce. Consider this
// object:
// const o = { a: true, 1: true, [Symbol.iterator]: true, }
//
// `keyof typeof o` will produce the type `typeof Symbol.iterator | "a" | 1
//
// But if we look at the actual runtime value of Object.keys(o)
// the array will be: ['1', 'a']
//
// Note that '1' is a string, not a number. And symbol keys are dropped.
// So we can't just use `keyof typeof o` as the type argument for the
// array produced by Object.keys.
//
// AsObjectKeys<typeof o> will instead give us the type "a" | "1"
// which is correct.
type AsObjectKeys<T extends keyof any> = T extends string
  ? T
  : T extends number
  ? `${T}`
  : never;

// Deeply intersect an object with a given type, e.g.:
//
// type Object = { content: { text: string } };
// type GivenType = { __typename: string };
// type ChatMessage = With<Object, GivenType>;
//
// ✅ Pass:
//
// const correct: ChatMessage = {
//   __typename: 'ChatMessage',
//   content: {
//     __typename: 'TextMessageContent',
//     text: 'hey milo',
//   },
// };
//
// ❌ Fail:
//
// const incorrect: ChatMessage = {
//   content: {
//     text: 'hey milo',
//   },
// };
export type With<T, U> = T extends Array<infer R>
  ? Array<With<R, U>>
  : T extends object
  ? U & { [P in keyof T as Exclude<P, keyof U>]: With<T[P], U> }
  : T;
