import { commitMutation, graphql } from 'react-relay';
import type { Environment } from 'relay-runtime';

import type {
  UpdatableByUserFeatureFlagEnum,
  featureFlagsMutation,
} from './__generated__/featureFlagsMutation.graphql';

export const mutation = ({ environment }: { environment: Environment }) => {
  return (
    featureName: UpdatableByUserFeatureFlagEnum,
    featureValue: boolean,
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      commitMutation<featureFlagsMutation>(environment, {
        mutation: graphql`
          mutation featureFlagsMutation(
            $featureName: UpdatableByUserFeatureFlagEnum!
            $featureValue: Boolean!
          ) {
            updateFeatureFlag: updateTypedFeatureFlag(
              feature: $featureName
              value: $featureValue
            ) {
              viewer {
                featureFlags: typedFeatureFlags {
                  ...featureFlags @relay(mask: false)
                }
              }
            }
          }
        `,
        variables: { featureName, featureValue },
        onCompleted: ({ updateFeatureFlag }) =>
          updateFeatureFlag && updateFeatureFlag.viewer !== null
            ? resolve() // intentionally discard result
            : reject(
                new Error(
                  'empty mutation result: feature flags may be misconfigured',
                ),
              ),
        onError: reject,
      });
    });
  };
};
