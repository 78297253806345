import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import MainMenu from 'ms-components/MainMenu';

import type { MainMenuStandaloneQuery } from './__generated__/MainMenuStandaloneQuery.graphql';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  debug?: boolean;
};
// A component for lazy loading the main menu.
const MainMenuStandalone = ({ isOpen, onClose, debug }: Props) => {
  const { props, error } = useQuery<MainMenuStandaloneQuery>(
    graphql`
      query MainMenuStandaloneQuery {
        viewer {
          ...MainMenu_viewer
        }
      }
    `,
    {},
  );
  if (error) throw error;
  if (!props) return null;
  return (
    <MainMenu
      isOpen={isOpen}
      onClose={onClose}
      viewer={props.viewer}
      debug={debug}
    />
  );
};
export default MainMenuStandalone;
