import { map } from 'ramda';

import { shadeHexString } from 'ms-utils/colors';

export const lanternColors = Object.freeze({
  // Brand colours
  grey: '#3E3E4C',
  grey10: '#C7C5C6',
  peachPink: '#D25564',
  peachPink90: '#E18C8A',
  peachPink20: '#F0CDC9',
  peachPink10: '#F1E8E5',

  // Supporting colours
  rose: '#CB7E6B',
  rose20: '#F0CEC6',
  roseBud: '#FFA8B2',
  wood: '#774D46',
  wood90: '#AD825E',
  gold: '#DFA830',
  gold90: '#E6BF80',
  gold50: '#E6CFA3',
  gold20: '#EADBBD',
  pine: '#4A7068',
  pine90: '#6C8680',
  pine50: '#8AB4AA',
  pine20: '#A4C0BB',
  pine10: '#D9DBD4',
  eggplant20: '#D7D6E8',
  eggplant40: '#B1AED1',
  eggplant15: '#CFCEE3',
});

// Colours source of truth: https://www.figma.com/file/2SuTQvoqSK2CHryyxqGvid/DLS-%E2%80%93-Numero-2020?node-id=9%3A31
// Colour names come from http://chir.ag/projects/name-that-color
export const colors = Object.freeze({
  astronaut: '#26276D',
  bayOfMany: '#2E3696',
  lochmara: '#0875BE',
  java: '#1CB7C8',
  purpleHeart: '#7547D8',
  white: '#FFFFFF',
  saffron: '#F4B42E',
  lightApricot: '#FDDFB3',
  lighPeach: '#FFD8C2',
  crusta: '#FD7B33',
  cinnabar: '#E74C3C',
  cornflowerBlue: '#5758F7',
  mountainMeadow: '#1ABC9C',
  chateauGreen: '#40B261',
  genoa: '#13816D',
  ebony: '#0D0D21',
  vulcan: '#0D1E2A',
  mako: '#424548',
  nevada: '#606F74',
  dustyGray: '#9A9A9A',
  santasGray: '#9A9DAB',
  iron: '#D4D7D9',
  ironLight: '#E5E6E8',
  porcelain: '#F1F2F3',
  athensGray: '#F9FAFB',
  tropicalBlue: '#D5E8F9',
  cloudBurst: '#21374D',
  pickledBluewood: '#34495E',
  hitGray: '#9EACB2',
  shuttleGray: '#5E6D7F',
  valencia: '#D13A2A',
  provincialPink: '#FDEBE8',
  watusi: '#FFD6DA',
  snuff: '#E1E2F1',
  shakespeare: '#3991CB',
  matisse: '#195E8C',
  gamboge: '#D5950E',
  scampi: '#585EAB',
  mystic: '#E5E8EE',
  indigo: '#4F59C9',
  cerulean: '#0988DC',
  frenchPass: '#B6E0FC',
  grayChateau: '#99A4AF',
  chestnutRose: '#D24D57',
  brickRed: '#CF3551',
  curiousBlue: '#3498db',
  deepKoamaru: '#121966',
  governorBay: '#3B4DBA',
  papayaWhip: '#FDF0D5',
  melrose: '#AAB6FE',
  linkWater: '#D6DBF4',
  black: '#000000',
  scorpion: '#585858',
  mako93: 'rgba(66, 69, 72, 0.93)',
  abbey: '#545759',
  boulder: '#757575',
  silver: '#bbbbbb',
  seashell: '#F8F8F8',
  sunflowerSeaShell: '#F5F5F5',
  decoratorSeaShell: '#F1F1F1',
  // special case for inline question selector paging buttons
  buttonSeaShell: '#F8F8F8',
  serenade: '#ffede2',
  aliceBlue: '#F3F9FF',
  regentGray: '#7E8D9C',
  shamrock: '#2ecc71',
  surfieGreen: '#0e7b63',
  tara: '#c9efd4',
  astronaut10: 'rgba(46, 54, 150, 0.1)',
  polar: '#e1f0f9',
  eastSide: '#a194d2',
  burntSienna: '#f06360',
  christine: '#e2720e',
  wispPink: '#fdf1f1',
  turquoise: '#2cd6b4',
  twilightBlue: '#edfdff',
  niagra: '#03A887',
  tangerine: '#E89D00',
  teal: '#36C8D8',
  tanSaffron: '#F4B42E',
  persia: '#838CF8',
  hitpink: '#FFB68C',
  almond50: '#F9F5EE',
  almond60: '#F3EDDF',
  almond: '#E0DBCF',
  eggplant: '#5D51AE',
  eggplant90: '#8C83C7',
  eggplant10: '#EFEEF7',
  eggplantLight: '#E9E7EB',
  neutralGray: '#3E3E4C',
  grey90: '#6F6F82',
  iceberg: '#CAEFE7',
  morningGlory: '#ACE3D9',
  blueGem: '#300391',
  viking: '#66B1E2',
  transparentWhite: 'rgba(255, 255, 255, .4)',
  elfGreen: '#00856B',
  lightCyan: '#CBE8EC',
  ...lanternColors,
});

const generatedAlternateColorsBasis = Object.freeze({
  almond: '#E0DBCF',
  eggplant: '#5D51AE',
  eggplant90: '#8C83C7',
  eggplant10: '#EFEEF7',
  grey90: '#6F6F82',
  neutralGray: '#3E3E4C',
  ...lanternColors,
});

const generatedAlternateColors: {
  [K in keyof typeof generatedAlternateColorsBasis]: string;
} = map(color => shadeHexString(color, 0.2), generatedAlternateColorsBasis);

// some, but not all, of the colors have one alternate color to use in variation (on hover, on pressed, or other)
// names are not of the colors themselves, but refer to the one for which they are the variation.
const staticAlternateColors = Object.freeze({
  mountainMeadow: '#16a085',
  matisse: '#195e8c',
  dustyGray: '#898989',
  chateauGreen: '#379953',
  cinnabar: '#bf3f32',
  christine: '#e25012',
  shamrock: '#26ab5e',
  surfieGreen: '#0b5f4d',
  ironLight: '#d4d7d9',
  white: 'rgba(255,255,255,0.7)',
  java: '#08aabc',
  eastSide: '#8d7fc0',
  saffron: '#f39c12',
  astronaut: '#26276D',
  bayOfMany: '#26276D',
  governorBay: '#26276D',
  cornflowerBlue: '#3435D8',
  lochmara: '#195E8C',
  crusta: '#E2720E',
  santasGray: '#767986',
  grayChateau: '#69647F',
  cloudBurst: colors.shuttleGray,
  pickledBluewood: colors.pickledBluewood,
  shuttleGray: '#4E5D6F',
  iron: '#B4B7B9',
  brickRed: '#B03677', // TODO verify with designers
  hitpink: '#DE590F',
  persia: '#3B4DBA',
  almond50: '#E0DBCF',
  // special case for inline question selector paging buttons
  buttonSeaShell: colors.seashell,
  transparentWhite: 'rgba(255, 255, 255, .2)',
});

export const alternateColors = Object.freeze({
  ...staticAlternateColors,
  ...generatedAlternateColors,
});

export type AlternateColorName = keyof typeof alternateColors;

export const desaturatedColors = Object.freeze({
  lochmara: '#DAEBF6',
  bayOfMany: '#E1E2F1',
});

export type ColorName = keyof typeof colors;
